import React from 'react'

import { Section } from 'components/Section'

import Body from './content/body.mdx'

export const Story = () => (
  <Section id="about-us">
    <Body />
  </Section>
)
