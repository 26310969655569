import React from 'react'

import * as styles from './Splash.module.scss'

export const Splash = () => (
  <div className={styles.splash}>
    <div className={styles.splashText}>
      <p>Welcome to</p>
      <h1 className={styles.title}>Sky Vigil Care</h1>
      <p>Providing specialised temporary &amp; permanent solutions within health &amp; social care</p>
    </div>
  </div>
)