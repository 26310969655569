import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Sky Vigil Care provides support workers/ carers who can work throughout the night, which is called waking night shift.
When you require reassurance only two times a night for regular checks and monitoring, this type of care is called a sleeping night shift.
We can also arrange for a sitting care or respite service to provide relief for your regular carer or provide a break for a family member that is providing you with care.
You can discuss these overnight care packages with our Registered Manager to negotiate the charge fees for each service.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      