import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Careers & Training`}</h2>
    <p>{`Sky Vigil Care promotes training and supports staff in acquiring new skills and continual personal development.
We liaise with the commissioning team and the Care Quality Commission normally inspects our service and staff will be trained to fill any gaps identified during service delivery audits.`}</p>
    <p>{`Sky Vigil Care has internal recruitment and staff selection procedures which are specially designed to comply with the legislative and regulatory requirements.
New staff are recruited through face-to-face interviews followed by Criminal Records Bureaux (CRB) checks, verification of training, qualifications and skills certificates, provision of two satisfactory checks and work experience references and evidence of personal commitment and dedication to the care industry.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      