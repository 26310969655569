import React, { useState } from 'react'

import * as styles from './ContactForm.module.scss'

const Required = () => <span className={styles.req}>&nbsp;*</span>

export const ContactForm = () => {
  const [state, setState] = useState({
    name: '',
    email: '',
    message: '',
  });

  const handleChange = e =>
    setState({
      ...state,
      [e.target.name]: e.target.value,
    })

  const { name, email, message } = state

  return (
    <>
      <h3>Get in touch</h3>
      <form name="contact" method="post" data-netlify="true" data-netlify-honeypot="bot-field">
        <input type="hidden" name="form-name" value="contact" />
        <label htmlFor="name">
          Name
          <Required />
          <input
            className={styles.input}
            id="name"
            type="text"
            name="name"
            required
            value={name}
            onChange={handleChange}
          />
        </label>

        <label htmlFor="email">
          Email
          <Required />
          <input
            className={styles.input}
            id="email"
            type="email"
            name="email"
            required
            value={email}
            onChange={handleChange}
          />
        </label>

        <label htmlFor="message">
          Message:
          <Required />
          <textarea
            className={styles.input__area}
            name="message"
            required
            value={message}
            onChange={handleChange}
          />
        </label>
        <button className={styles.button} type="submit">
          Send
        </button>
      </form>
    </>
  )
}
