import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Carers are our lifeblood. If you’re looking for temporary or permanent work we want to hear from you!`}</p>
    <p>{`Our commitment is to provide you with the best employment opportunities we can and we understand how important it is for us to find you the right role.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      