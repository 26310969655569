import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Our Services`}</h2>
    <p>{`Select from our flexible home care / domiciliary care services, anything from 30-minute home visits for ‘personal care’ to a 24-hour live-in care services.
Sky Vigil Care offers short-term hospital to home care `}<em parentName="p">{`(following an illness or because your usual carer is having a break)`}</em>{`, or long-term homecare service and support.`}</p>
    <p>{`You can discuss with our Registered Manager the service charges for these services.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      