import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`There are various options for deciding whether to continue receiving visiting care or switch to a live-in carer:`}</p>
    <ul>
      <li parentName="ul">{`Does your existing care plan include continuity when your care needs change?`}</li>
      <li parentName="ul">{`Have you been involved in developing your care support plan that incorporates your specific needs?`}</li>
      <li parentName="ul">{`Has training been provided to carer to adapt to your changing care needs?`}</li>
      <li parentName="ul">{`Would you prefer one live-in carer, or you can cope with a variety of different visiting carers?`}</li>
      <li parentName="ul">{`Are you prepared to wait for a carer or have the peace of mind that a live-in carer is already there in your home?`}</li>
      <li parentName="ul">{`Has your timetable been considered above that of the care provider agency? and`}</li>
      <li parentName="ul">{`Have cost savings been considered regarding receiving visiting care versus the ability of live-in care to look after two people, e.g. an elderly couple?`}</li>
    </ul>
    <p>{`You can discuss with our Registered Manager the service charges for these services.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      