import React from 'react'

import { Section } from 'components/Section'

import Intro from './content/intro.mdx'
import Body from './content/body.mdx'

export const Training = () => (
  <Section id="careers-training">
    <Intro />
    <Body />
  </Section>
)
