import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Sky Vigil Care specialist supported living and outreach services provide support for individuals in the community who have Acquired Brain Injuries, Autism, Learning Disabilities and other Mental Health Needs.
Sky Vigil Care provides person-centred support which enables independence, maintains dignity and meets the individual’s needs.
This approach is focussed on the best outcome for people receiving care by working together with their family we can understand their life story, choices and aspirations.
We work in partnership with Local Health Professionals such as Social Workers, District nurses, GP, and specialists such as Speech and Language Therapists, Physiotherapists and Occupational Therapists.`}</p>
    <h3>{`Person Centred Planning`}</h3>
    <p>{`Sky Vigil Care develops together with the family and all other stake holders, a person-centred plan that incorporates any special communication needs, lifestyle choices and individual choices for the people receiving care.
You can discuss with our Registered Manager the service charges for these services.`}</p>
    <p>{`Supported living includes:`}</p>
    <ul>
      <li parentName="ul">{`Focus on dignity and independence with personal care tasks;`}</li>
      <li parentName="ul">{`Building confidence and communication skills;`}</li>
      <li parentName="ul">{`Eating well, staying healthy and good body image;`}</li>
      <li parentName="ul">{`Developing fitness through walks and exercise;`}</li>
      <li parentName="ul">{`Medicine administration and arranging healthcare appointments;`}</li>
      <li parentName="ul">{`Managing money and developing good shopping habits;`}</li>
      <li parentName="ul">{`Journey planning to gain confidence when travelling;`}</li>
      <li parentName="ul">{`Discovering new hobbies, getting out and having fun!`}</li>
      <li parentName="ul">{`Accessing vocational training and work experience`}</li>
      <li parentName="ul">{`Managing bills and interpersonal skills;`}</li>
      <li parentName="ul">{`Building social skills and interaction with others; and`}</li>
      <li parentName="ul">{`Access to legal advocacy and support.`}</li>
    </ul>
    <p>{`Sky Vigil Care supported living service provides up to 24-hour support, including overnight support when needed within the family home or in an individual’s supported tenancy residence.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      