import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Our Mission Statement`}</h2>
    <p>{`Sky Vigil Care is a Health and Social care organisation that provides personal care, including registered Nursing Health Care nationwide.
Domiciliary Care also known as Home care support services is provided within the comforts of your own home environment.
‘Personalised Care’ is a theme we believe brings out a quality in care services.
This means that your care package is tailor made to suit your needs and requirements.
Working together with our customers, their families and other stakeholders, it means that your care package is tailor made, uniquely designed and individualised to suit your needs and requirements within the comfort of your own home environment.
Sky Vigil Care understands and recognises the importance of developing a relationship with your assigned support worker or workers and we work to ensure and promote continuity of care wherever possible.`}</p>
    <p>{`Our aims and goal is to bring you a service that will promote quality of life, promote your independence and wellbeing despite your life challenges.
We believe this can be achieved by working together with you, your family and other stakeholders in the field of your health and care.
We are committed to providing quality services for our customers/Service Users by caring, competent, well trained staff in the Service User’s own home environment.
It is our aim to ensure that you remain in full control of what happens around you.`}</p>
    <p>{`We provide you with the home care and support package you desire within your home environment.
Its your life and your choice, so it must be your way.
With the help of our thoroughly trained caring team, your care package will be fully set out to reach a satisfactory care intervention.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      