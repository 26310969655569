import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Living with a complex illness can be very challenging, so we have designed Complex Care Program specifically for individuals with complicated conditions.
Our carers are well qualified in working with individuals with severe illness and are skilled in helping our clients improve their quality of life.`}</p>
    <p>{`This care service also supports the client's family as they take care of their loved one who is suffering from a degenerative condition, enduring physical or mental disabilities, or someone who has recently returned home from hospital wards like intensive care.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      