import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Our Support Carers are specially trained in End of Life care; provide support, personal care and supervision.
Providing a patient-centred care service that supports your independence and maintains your dignity within your own home.
Sky Vigil Care works together with Social Workers, District Nurses, your GP and the NHS Hospitals to provide a reliable and responsive service, through our patient care plan and dedicated carers provide support and home care assistance when you need it most.
This can be for as short a period as 30 minutes to three hours or more depending on your needs.
You can contact our Registered Manager to discuss our visiting care costs which would be appropriate for you as we tailor our services to your specific needs.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      