import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Sky Vigil Care is a health and social care organisation.
We provide Domiciliary Care also known as Homecare and Supported Living services within the comforts of your own home environment. `}</p>
    <p>{`‘Personalised Care’ is a theme we believe brings out quality and individuality in home care support services.
This means that your care package is tailor made to suit your needs and requirements. `}</p>
    <p>{`Working together with our customers, their families and other stakeholders means that your care package is uniquely tailor-made and individualised to suit your needs and requirements — within the comfort of your own home environment.`}</p>
    <p>{`We provide high quality ‘Personalised Care’ services. `}<small>{`(Click below to learn more)`}</small></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      