import React from 'react'

import { Section } from 'components/Section'

import Body from './content/body.mdx'

export const Mission = () => (
  <Section id="our-mission">
    <Body />
  </Section>
)
