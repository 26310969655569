import React from 'react'

import { Section } from 'components/Section'
import { ServiceList } from './ServiceList'

import Body from './content/body.mdx'

export const Services = () => (
  <Section id="care-services">
    <Body />
    <ServiceList />
  </Section>
)
