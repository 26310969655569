import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Sky Vigil Care understands that life starts to slow down as people get older.
We help to plan social activities around your normal routine by providing a friendly, caring companion who visits you regularly.
Our care assistants become a trusted companion who provides stimulation and someone to talk to in the comfort of your own home and can also accompany you on visits with friends, family, and to other social events.`}</p>
    <p>{`At Sky Vigil Care our companionship care covers a wide range of activities such as the following:`}</p>
    <ul>
      <li parentName="ul">{`Accompanying you to and from your appointments`}</li>
      <li parentName="ul">{`Someone to converse with and general companionship`}</li>
      <li parentName="ul">{`Arranging outings and visits`}</li>
      <li parentName="ul">{`Doing general domestic duties such as laundry and cleaning`}</li>
      <li parentName="ul">{`Help with meal preparation`}</li>
      <li parentName="ul">{`Arranging and going shopping`}</li>
      <li parentName="ul">{`Driving and taking you around`}</li>
      <li parentName="ul">{`Taking part in your favourite hobbies`}</li>
      <li parentName="ul">{`Driving you where you would like to go`}</li>
    </ul>
    <p>{`Sky Vigil Care aims to ensure that our customers can lead happy, healthy and stimulating lives in the comfort of their own homes.
We also understand and recognise the importance of developing a relationship with your assigned support worker or workers and we work to ensure and promote continuity of care wherever possible.
You can discuss with our Registered Manager the service charges for these services.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      