import React from 'react'

import { Section } from 'components/Section'

import Body from './content/body.mdx'
import Services from './content/services.mdx'

export const Intro = () => (
  <Section id="intro">
    <h2>Quality Care Service</h2>
    <div>
      <Body />
    </div>
    <div>
      <Services />
    </div>
  </Section>
)
