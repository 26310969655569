import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Sky Vigil Care supports people who have Learning Disabilities and complex needs who may also be living with other physical and mental challenges including visual and hearing impairment.
Our person-centred approach provides positive support which enables people to have choices and achievable goals.
We encourage and motivate each person to live a full life in a supported, safe and nurturing environment.
feel good about themselves.`}</p>
    <p>{`We encourage people to gain confidence and aspire to live life to the full regardless of the challenges they face.
Our outcome focussed approach helps people to lead happy and fulfilled lives.
We encourage people to participate in the care plan decisions regarding their care and support packages.`}</p>
    <p>{`Sky Vigil Care services include:`}</p>
    <ul>
      <li parentName="ul">{`Personal care;`}</li>
      <li parentName="ul">{`Arranging social and recreational visits;`}</li>
      <li parentName="ul">{`Education and vocational training;`}</li>
      <li parentName="ul">{`Managing personal finances and budgeting;`}</li>
      <li parentName="ul">{`Independent skills and mentoring;`}</li>
      <li parentName="ul">{`Enabling behaviour change;`}</li>
      <li parentName="ul">{`Networking among family, friends in the community;`}</li>
      <li parentName="ul">{`Undertaking household chores, tasks and routines;`}</li>
      <li parentName="ul">{`Effective journey planning and confidence in travelling;`}</li>
      <li parentName="ul">{`Enabling access to legal advice and advocacy; and`}</li>
      <li parentName="ul">{`Building links with other healthcare providers.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      