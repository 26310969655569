import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Our professional skilled health care assistants and support workers are dedicated and fully trained to:`}</p>
    <ul>
      <li parentName="ul">{`Assist with a ‘good morning’ start to the day, helping you to get up, wash, shower or bathe, dress and have breakfast;`}</li>
      <li parentName="ul">{`Remind or assist you to take your medicines and collect or return medication from your pharmacy or dispensing GP surgery;`}</li>
      <li parentName="ul">{`Prepare meals with or for you and assist you at mealtimes;`}</li>
      <li parentName="ul">{`Collect your pension for you or with you;`}</li>
      <li parentName="ul">{`Shop with you or help you to make a shopping list, go to the shops, come back and put it all away;`}</li>
      <li parentName="ul">{`Help with your household tasks such as laundry or ironing and keeping your home clean and tidy;`}</li>
      <li parentName="ul">{`Support you with social activities such as going out for a walk, attending a daycentre, visiting friends or family, going to your church or club etc.
Give a little pampering when you need it – the occasional visit to organise bathing, clean clothes, fresh bedding and a thoughtfully prepared meal before bedtime;`}</li>
      <li parentName="ul">{`At the end of each day, some help with getting ready for bed; and`}</li>
      <li parentName="ul">{`Overnight ‘sleeper’ or waking support, respite care or a 24-hour live-in care service.å`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      