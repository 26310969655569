import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Sky Vigil Care has gained experience in NHS Continuing Healthcare packages arranged and funded solely by the NHS for individuals, aged 18 years and over living in the community with ongoing complex or intense or unpredictable healthcare needs.
This is usually at a care home or in your own home.
Our care team has been involved in the screening process, which includes the Social Worker, GP, your family, District Nurse and other healthcare professionals.
The Continuing Care team consisting of multi-disciplinary health and social care professionals makes a full and comprehensive health needs assessment of your eligibility.`}</p>
    <p>{`When the NHS continuing healthcare is provided in a care home, it will cover the care home fees, including the cost of accommodation, personal care and healthcare costs.
If NHS continuing care is provided in the home of the person, it will cover personal care and healthcare costs.
You will continue to be entitled to access the full range of health services your GP, Hospital, out-patients and District nursing.
You can discuss with our Registered Manager the service charges for these services.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      