import React from 'react'

import * as styles from './Section.module.scss'

export const Section = ({ id, children, className }) => (
  <section id={id} className={`${styles.section} ${className}`}>
    <div className={styles.container}>
      <div className={styles.content}>{children}</div>
    </div>
  </section>
)
