import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3>{`Contact Information`}</h3>
    <h4>{`Address`}</h4>
    <p>{`Unit 129 Sky Vigil Care Ltd.`}<br />{`
Corinium House`}<br />{`
Barnwood Point`}<br />{`
Gloucester`}<br />{`
GL4 3HX`}</p>
    <h4>{`Phone`}</h4>
    <a href="tel:01452341993" className="contact-item">01452341993</a>
    <h4>{`Email`}</h4>
    <a href="mailto:info@skyvigilcare.co.uk" className="contact-item">info@skyvigilcare.co.uk</a>
    <small>
  Sky Vigil Care Ltd vows to protect your privacy.
  <br />
  We ensure that your details are kept confidential and are to be used
  exclusively by our agency.
    </small>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      