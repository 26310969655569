import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`For Personal Care, our goal is to promote quality of life for both the customer and their families.
We are here to serve you!
Your personal care plan will provide support with personal hygiene, (assistance to get up in the mornings, bathing/shower, dressing, skin care, hair care and/or preparing meals and preparing for bed at night).
Oral hygiene, toileting, applying creams and lotions, dressing, preparing to get in and out of bed, medication supervision or administration, and maintaining your personal appearance.`}</p>
    <p>{`Sky Vigil Care tailors care around your individual needs and preferences to ensure you remain and maintain your individuality as comfortable as possible.
We encourage you to retain as much independence and dignity as possible.`}</p>
    <p>{`The organisation has fully trained carers who become familiar faces to you, dedicated to serve you and this promotes easy rapport and continuous care in the following:`}</p>
    <ul>
      <li parentName="ul">{`Teeth cleaning and Oral hygiene;`}</li>
      <li parentName="ul">{`Help with beauty and personal appearance in applying make-up;`}</li>
      <li parentName="ul">{`Assistance in hair care and shaving;`}</li>
      <li parentName="ul">{`Application of body lotions and medicated creams;`}</li>
      <li parentName="ul">{`Assistance with going to toilet, using a commode and bed pan;`}</li>
      <li parentName="ul">{`Help in cleaning private parts and changing incontinence pads;`}</li>
      <li parentName="ul">{`Monitoring and changing stroma or catheter, bowl bags, etc.`}</li>
      <li parentName="ul">{`Assist you to change under clothes, clothes and to dress up;`}</li>
      <li parentName="ul">{`Support in getting in and out of bed;`}</li>
      <li parentName="ul">{`Monitoring for diabetic patients;`}</li>
      <li parentName="ul">{`Support to maintain a comfortable position in bed, monitoring to avoid bed sores;`}</li>
      <li parentName="ul">{`Meal preparation and feeding; and`}</li>
      <li parentName="ul">{`Remind or assist you to take your medicines and collect or return medication from your pharmacy or dispensing GP surgery; andLeave room for things you prefer to do for yourself.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      