import React from 'react'

import { Section } from 'components/Section'

import Clients from './content/clients.mdx'
import Carers from './content/carers.mdx'

import * as styles from './Audience.module.scss'

export const Audience = () => (
  <Section id="audience" className={styles.section}>
    <div className={styles.container}>
      <div className={styles.area}>
        <h3 className={styles.type}>Clients</h3>
        <Clients />
        <a className={styles.contactButton} href="#contact-us">Contact Sky Vigil Care</a>
      </div>
      <div className={styles.spacer} />
      <div className={styles.area}>
        <h3 className={styles.type}>Carers</h3>
        <Carers />
        <a className={styles.contactButton} href="#contact-us">Contact Recruiter</a>
      </div>
    </div>
  </Section>
)
