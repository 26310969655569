import React from 'react'

import { ServiceItem } from '../ServiceItem'

import { services } from '../content/services'

import * as styles from './ServiceList.module.scss'

export const ServiceList = () => (
  <ul aria-label="list of care services" className={styles.list}>
    {services.map(({ key, title, Body }) => (
      <ServiceItem key={key} name={key} title={title} Body={Body} />
    ))}
  </ul>
)
