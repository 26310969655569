import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3>{`Domiciliary / Home Care services include:`}</h3>
    <ul>
      <li parentName="ul">{`Personal care`}</li>
      <li parentName="ul">{`Supported living`}</li>
      <li parentName="ul">{`Palliative Care / End-of-life care`}</li>
      <li parentName="ul">{`Learning disability`}</li>
      <li parentName="ul">{`Physical disability`}</li>
      <li parentName="ul">{`Complex care`}</li>
      <li parentName="ul">{`Respite care`}</li>
      <li parentName="ul">{`Live in care`}</li>
      <li parentName="ul">{`Sitting night`}</li>
      <li parentName="ul">{`Waking night`}</li>
      <li parentName="ul">{`Emergency placement`}</li>
      <li parentName="ul">{`Residential placement for adult with disability`}</li>
      <li parentName="ul">{`Community support for learning disability`}</li>
      <li parentName="ul">{`Autistic Spectrum Disorder`}</li>
      <li parentName="ul">{`Asperger’s`}</li>
      <li parentName="ul">{`Behaviour that challenges`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      