import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`We will never compromise on the standard of staff that we provide or the customer service that we offer to our clients.`}</p>
    <p>{`Contact us about your Health & Social Care needs and one of our experienced consultants will get back to you to discuss your requirements.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      