import React, { useState } from 'react'

import * as styles from './ServiceItem.module.scss'

const getExpandedText = expanded => (expanded ? 'expanded' : 'collapsed')

const Arrow = ({ expanded }) =>
  expanded ? (
    <span aria-hidden="true">&nbsp;&#65514;</span>
  ) : (
    <span aria-hidden="true">&nbsp;&#65516;</span>
  )

export const ServiceItem = ({ name, title, Body }) => {
  const [expanded, setExpanded] = useState(false)

  const toggleBody = () => {
    setExpanded(!expanded)
  }

  return (
    <li className={styles.item}>
      <button
        type="button"
        className={styles[`control__${getExpandedText(expanded)}`]}
        aria-controls={`service-${name}`}
        aria-expanded={expanded.toString()}
        id={`accordion-control-${name}`}
        onClick={toggleBody}
      >
        {title}
        <Arrow expanded={expanded} />
      </button>
      <div
        className={styles[`body__${getExpandedText(expanded)}`]}
        aria-hidden={(!expanded).toString()}
        id={`service-${name}`}
      >
        <Body />
      </div>
    </li>
  )
}
