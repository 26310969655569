import React from 'react'

import { Section } from 'components/Section'
import { ContactForm } from 'components/ContactForm'

import ContactDetails from './content/details.mdx'

import * as styles from './Contact.module.scss'

export const Contact = () => (
  <Section id="contact-us">
    <h2>Contact Us</h2>
    <div className={styles.container}>
      <div className={styles.area}>
        <ContactForm />
      </div>
      <div className={styles.area}>
        <ContactDetails />
      </div>
    </div>
  </Section>
)
