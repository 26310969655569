import PersonalCare from './services/personal-care.mdx'
import SupportedLiving from './services/supported-living.mdx'
import ReturningHome from './services/returning-home.mdx'
import PalliativeCare from './services/palliative-care.mdx'
import OvernightCare from './services/overnight-care.mdx'
import WakingNights from './services/waking-nights.mdx'
import LiveInCare from './services/live-in-care.mdx'
import ElderlyCare from './services/elderly-care.mdx'
import AlzheimersDementiaCare from './services/alzheimers-dementia-care.mdx'
import ComplexCare from './services/complex-care.mdx'
import Disability from './services/disability.mdx'
import CompanionshipCare from './services/companionship-care.mdx'

export const services = [
  { title: 'Personal Care', key: 'personal-care', Body: PersonalCare },
  { title: 'Supported Living', key: 'supported-living', Body: SupportedLiving },
  {
    title: 'Returning Home From Hospital Care',
    key: 'returning-home-from-hospital-care',
    Body: ReturningHome,
  },
  {
    title: 'Palliative Care/ End of Life',
    key: 'palliative-care-end-of-life',
    Body: PalliativeCare,
  },
  { title: 'Overnight Care', key: 'overnight-care', Body: OvernightCare },
  { title: 'Waking Nights', key: 'waking-nights', Body: WakingNights },
  { title: 'Live In Care', key: 'live-in-care', Body: LiveInCare },
  { title: 'Elderly Care', key: 'elderly-care', Body: ElderlyCare },
  {
    title: 'Alzheimers and Dementia Care',
    key: 'alzheimers-and-dementia-care',
    Body: AlzheimersDementiaCare,
  },
  {
    title: 'Complex or Continuing Care',
    key: 'complex-or-continuing-care',
    Body: ComplexCare,
  },
  {
    title: 'Learning and Physical Disability',
    key: 'learning-and-physical-disability',
    Body: Disability,
  },
  {
    title: 'Companionship Care',
    key: 'companionship-care',
    Body: CompanionshipCare,
  },
]
