import React, { useState } from 'react'

import { SEO } from 'components/SEO'
import { Layout } from 'components/Layout'
import { Splash } from 'components/Splash'
import { Audience } from 'components/Audience'
import { ImageSection } from 'components/ImageSection'
import { Intro } from 'components/Intro'
import { Mission } from 'components/Mission'
import { Services } from 'components/Services'
import { Story } from 'components/Story'
import { Training } from 'components/Training'
import { Contact } from 'components/Contact'

import peopleHands from 'images/people-holding-hands.jpg'
import teamHands from 'images/team-hands.jpg'
import handsBasking from 'images/hands-basking.jpg'
import coupleTablet from 'images/couple-tablet.jpg'
import restingHands from 'images/resting-hands.jpg'
import handsGrip from 'images/hands-grip.jpg'

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Splash />
    <Audience />
    <ImageSection source={peopleHands} alt="older people holding hands" />
    <Intro />
    <ImageSection source={teamHands} alt="team hands linked together" />
    <Mission />
    <ImageSection source={handsBasking} alt="a pair of hands basking in the sunlight" />
    <Services />
    <ImageSection source={coupleTablet} alt="couple smile as they use a tablet" />
    <Story />
    <ImageSection source={restingHands} alt="resting hands on top of someone elses hands" />
    <Training />
    <ImageSection source={handsGrip} alt="hands grip a thick wool blanket" />
    <Contact />
  </Layout>
)

export default IndexPage
