import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Our Story`}</h2>
    <p>{`Sky Vigil Care is a Health and social care provider organisation that provides personal care, including registered Nursing nationwide.
Domiciliary Care also known as Home care support services is provided within the comforts of your own home environment.
‘Personalised Care’ is a theme we believe brings out a quality in care services.
This means that your care package is tailor made to suit your needs and requirements.
Working together with our customers, their families and other stakeholders, it means that your care package is tailor made, uniquely designed and individualised to suit your needs and requirements within the comfort of your own home environment.`}</p>
    <p>{`We understand and recognise the importance of developing a relationship with your assigned support worker or workers and we work to ensure and promote continuity of care wherever possible.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      