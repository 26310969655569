import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Sky Vigil Care provides care and support overnight when required to help you maintain your normal daily living activities.
Our fully trained carers look after you and help with toileting, changing position in bed to prevent pressure sores and assist you take medication on time.
This enables you to stay in the comfort of your own home as you can call for help immediately you need it.
There are various reasons why it is better to have an overnight carer than be in a residential home, including:`}</p>
    <ul>
      <li parentName="ul">{`Reduce anxiety and stress for you and your family members`}</li>
      <li parentName="ul">{`Monitoring sleeping position in bed and help moving to prevent pressure sores`}</li>
      <li parentName="ul">{`Regular support with mobility difficulties after fall, stroke or hospital treatment`}</li>
      <li parentName="ul">{`Administering medication on time throughout the night`}</li>
      <li parentName="ul">{`Regular help for changing stoma, catheter and bowl bags`}</li>
      <li parentName="ul">{`Having a reliable and knowledgeable carer on hand to help particularly after a hospital operation or injury`}</li>
      <li parentName="ul">{`Someone on hand for those living with progressively worsening conditions such as dementia, Alzheimer’s and Parkinson’s disease`}</li>
      <li parentName="ul">{`Ability to call for help and responsive intervention from other specialists, nurses, GP and hospital for those living with complex care needs`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      