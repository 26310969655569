import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Sky Vigil Care provides care and support for people suffering from Dementia so that they continue to live in their own homes, local communities and to maintain their self-worth, social networks and interactions.
Providing the same carers helps dementia patients to remain confident by minimising changes to their familiar routines and environment around them.`}</p>
    <p>{`We provide carers who help maintain and engage in hobbies and interests, help with mobility and facilitate social interactions in the local area.
This promotes independence and maintains dignity for people living with dementia and memory loss within their own homes instead of receiving care in care homes.
Our carers have received specialist dementia and Alzheimer’s disease training and are able to provide one-to-one support and companionship so that other family members providing primary care can have a break.`}</p>
    <p>{`We are committed to providing all-round continuing care for people with dementia in their homes, which provides consistency and continuity of memories, familiar routine and environment for them.
We also provide live-in care, palliative care, domiciliary care and visiting care services.
You can discuss with our Registered Manager the service charges for these services.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      